import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProjeTuruMecraEsle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProjeTuruMecraEsle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  getFilterParametersByUserName: any[];
  getFilterParametersByUserName_dummy: any[];
  sbProjeTuruList: any[];
  sbProjeTuruList_dummy: any[];
  sbMecraGrupList: any[];
  sbMecraGrupList_dummy: any[];
  sbMecraList: any[];
  sbMecraList_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  ProjeTuruMecraEslestir: any[];
  ProjeTuruMecraEslestir_dummy: any[];
  FilterParametersSaveProjeTuruMecraEsle: number;
  FilterParametersSaveProjeTuruMecraEsle_dummy: number;
  MecraProjeTuruEsleInsert: number;
  MecraProjeTuruEsleInsert_dummy: number;
  MecraProjeTuruEsleDelete: number;
  MecraProjeTuruEsleDelete_dummy: number;
}

export class ProjeTuruMecraEsle_ScreenBase extends React.PureComponent<IProjeTuruMecraEsle_ScreenProps, any> {
  projeturumecraesle_356189_value_kuikaSelectBoxRef: React.RefObject<any>;
  projeturumecraesle_235392_value_kuikaSelectBoxRef: React.RefObject<any>;
  projeturumecraesle_42526_value_kuikaSelectBoxRef: React.RefObject<any>;
  projeturumecraesle_660947_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "f57b1e26-2c7f-4306-aca3-d8b710f6926d",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 625521, PropertyName: "value", Value: 'Müzik Bankası Başvuru Formu "Proje Türü / Mecra" Eşleştir' },
        { Id: 484566, PropertyName: "value", Value: "Proje Türü" },
        { Id: 946062, PropertyName: "value", Value: "Mecra Grup" },
        { Id: 969683, PropertyName: "value", Value: "Mecra" },
        { Id: 660947, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 789346, PropertyName: "title", Value: "Proje Türü" },
        { Id: 854825, PropertyName: "value", Value: "[datafield:projeturu]" },
        { Id: 812977, PropertyName: "title", Value: "Mecra Grup" },
        { Id: 679677, PropertyName: "value", Value: "[datafield:mecragrup]" },
        { Id: 152799, PropertyName: "title", Value: "Mecra" },
        { Id: 469334, PropertyName: "value", Value: "[datafield:mecratanim]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.projeturumecraesle_356189_value_kuikaSelectBoxRef = React.createRef();
    this.projeturumecraesle_235392_value_kuikaSelectBoxRef = React.createRef();
    this.projeturumecraesle_42526_value_kuikaSelectBoxRef = React.createRef();
    this.projeturumecraesle_660947_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      getFilterParametersByUserName: [],
      sbProjeTuruList: [],
      sbMecraGrupList: [],
      sbMecraList: [],
      SetValueOf: "",
      ProjeTuruMecraEslestir: [],
      FilterParametersSaveProjeTuruMecraEsle: "",
      MecraProjeTuruEsleInsert: "",
      MecraProjeTuruEsleDelete: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("projeturumecraesle", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ProjeTuruMecraEslePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("projeturumecraesle", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("projeturumecraesle", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ProjeTuruMecraEslePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      projeturumecraesle_356189_value:
        this.state.getFilterParametersByUserName?.at?.(0)?.projeTuruMecraEsleSBox1 ?? undefined,
      projeturumecraesle_235392_value:
        this.state.getFilterParametersByUserName?.at?.(0)?.projeTuruMecraEsleSBox2 ?? undefined,
      projeturumecraesle_42526_value:
        this.state.getFilterParametersByUserName?.at?.(0)?.projeTuruMecraEsleSBox3 ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  ProjeTuruMecraEslePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProjeTuruMecraEsle/ProjeTuruMecraEslePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getFilterParametersByUserName = result?.data.getFilterParametersByUserName;
    formVars.projeturumecraesle_356189_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].projeTuruMecraEsleSBox1
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].projeTuruMecraEsleSBox1
        : null;
    formVars.projeturumecraesle_356189_options = stateVars.sbProjeTuruList;
    formVars.projeturumecraesle_235392_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].projeTuruMecraEsleSBox2
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].projeTuruMecraEsleSBox2
        : null;
    formVars.projeturumecraesle_235392_options = stateVars.sbMecraGrupList;
    formVars.projeturumecraesle_42526_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].projeTuruMecraEsleSBox3
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].projeTuruMecraEsleSBox3
        : null;
    formVars.projeturumecraesle_42526_options = stateVars.sbMecraList;
    stateVars.sbProjeTuruList = result?.data.sbProjeTuruList;

    formVars.projeturumecraesle_356189_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].projeTuruMecraEsleSBox1
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].projeTuruMecraEsleSBox1
        : null;
    formVars.projeturumecraesle_356189_options = stateVars.sbProjeTuruList;
    stateVars.sbMecraGrupList = result?.data.sbMecraGrupList;

    formVars.projeturumecraesle_235392_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].projeTuruMecraEsleSBox2
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].projeTuruMecraEsleSBox2
        : null;
    formVars.projeturumecraesle_235392_options = stateVars.sbMecraGrupList;
    stateVars.sbMecraList = result?.data.sbMecraList;

    formVars.projeturumecraesle_42526_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].projeTuruMecraEsleSBox3
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].projeTuruMecraEsleSBox3
        : null;
    formVars.projeturumecraesle_42526_options = stateVars.sbMecraList;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProjeTuruMecraEslePageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProjeTuruMecraEslePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("projeturumecraesle_356189_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getFilterParametersByUserName?.length > 0
          ? stateVars.getFilterParametersByUserName[0].projeTuruMecraEsleSBox1
          : this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0].projeTuruMecraEsleSBox1
          : null
      ),
      null,
      "Id"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("projeturumecraesle_235392_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getFilterParametersByUserName?.length > 0
          ? stateVars.getFilterParametersByUserName[0].projeTuruMecraEsleSBox2
          : this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0].projeTuruMecraEsleSBox2
          : null
      ),
      null,
      "Id"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("projeturumecraesle_42526_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getFilterParametersByUserName?.length > 0
          ? stateVars.getFilterParametersByUserName[0].projeTuruMecraEsleSBox3
          : this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0].projeTuruMecraEsleSBox3
          : null
      ),
      null,
      "Id"
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProjeTuruMecraEslePageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProjeTuruMecraEslePageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      ProjeTuruId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_356189_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      ),
      MecraGrupId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_235392_value", "value", "sbMecraGrupList", "id", "id")
        ),
        "Guid"
      ),
      MecraId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_42526_value", "value", "sbMecraList", "id", "id")
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProjeTuruMecraEsle/ProjeTuruMecraEslePageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProjeTuruMecraEslestir = result?.data.projeTuruMecraEslestir;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProjeTuruMecraEslePageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProjeTuruMecraEslePageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.projeturumecraesle_356189_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].projeTuruMecraEsleSBox1
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].projeTuruMecraEsleSBox1
        : null
    );

    stateVars.dataSource_356189 = this.state.sbProjeTuruList;
    stateVars.dataSource_356189 = this.state.sbProjeTuruList;
    formVars.projeturumecraesle_235392_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].projeTuruMecraEsleSBox2
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].projeTuruMecraEsleSBox2
        : null
    );

    stateVars.dataSource_235392 = this.state.sbMecraGrupList;
    stateVars.dataSource_235392 = this.state.sbMecraGrupList;
    formVars.projeturumecraesle_42526_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].projeTuruMecraEsleSBox3
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].projeTuruMecraEsleSBox3
        : null
    );

    stateVars.dataSource_42526 = this.state.sbMecraList;
    stateVars.dataSource_42526 = this.state.sbMecraList;

    stateVars.dataSource_660947 = this.state.ProjeTuruMecraEslestir;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ProjeTuruMecraEsleComponent_653137_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ProjeTuruMecraEsle",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "464411",
      ReactSystemFunctions.convertToTypeByName("", "string"),
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProjeTuruMecraEsleComponent_356189_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProjeTuruMecraEsleSBox1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_356189_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "string"
      ),
      ProjeTuruMecraEsleSBox2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_235392_value", "value", "sbMecraGrupList", "id", "id")
        ),
        "string"
      ),
      ProjeTuruMecraEsleSBox3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_42526_value", "value", "sbMecraList", "id", "id")
        ),
        "string"
      ),
      ProjeTuruId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_356189_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      ),
      MecraGrupId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_235392_value", "value", "sbMecraGrupList", "id", "id")
        ),
        "Guid"
      ),
      MecraId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_42526_value", "value", "sbMecraList", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProjeTuruMecraEsle/ProjeTuruMecraEsleComponent_356189_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveProjeTuruMecraEsle = result?.data.filterParametersSaveProjeTuruMecraEsle;
    stateVars.ProjeTuruMecraEslestir = result?.data.projeTuruMecraEslestir;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProjeTuruMecraEsleComponent_356189_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProjeTuruMecraEsleComponent_356189_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_660947 = this.state.ProjeTuruMecraEslestir;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProjeTuruMecraEsleComponent_235392_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProjeTuruMecraEsleSBox1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_356189_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "string"
      ),
      ProjeTuruMecraEsleSBox2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_235392_value", "value", "sbMecraGrupList", "id", "id")
        ),
        "string"
      ),
      ProjeTuruMecraEsleSBox3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_42526_value", "value", "sbMecraList", "id", "id")
        ),
        "string"
      ),
      ProjeTuruId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_356189_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      ),
      MecraGrupId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_235392_value", "value", "sbMecraGrupList", "id", "id")
        ),
        "Guid"
      ),
      MecraId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_42526_value", "value", "sbMecraList", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProjeTuruMecraEsle/ProjeTuruMecraEsleComponent_235392_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveProjeTuruMecraEsle = result?.data.filterParametersSaveProjeTuruMecraEsle;
    stateVars.ProjeTuruMecraEslestir = result?.data.projeTuruMecraEslestir;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProjeTuruMecraEsleComponent_235392_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProjeTuruMecraEsleComponent_235392_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_660947 = this.state.ProjeTuruMecraEslestir;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProjeTuruMecraEsleComponent_42526_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProjeTuruMecraEsleSBox1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_356189_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "string"
      ),
      ProjeTuruMecraEsleSBox2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_235392_value", "value", "sbMecraGrupList", "id", "id")
        ),
        "string"
      ),
      ProjeTuruMecraEsleSBox3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_42526_value", "value", "sbMecraList", "id", "id")
        ),
        "string"
      ),
      ProjeTuruId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_356189_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      ),
      MecraGrupId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_235392_value", "value", "sbMecraGrupList", "id", "id")
        ),
        "Guid"
      ),
      MecraId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_42526_value", "value", "sbMecraList", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProjeTuruMecraEsle/ProjeTuruMecraEsleComponent_42526_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveProjeTuruMecraEsle = result?.data.filterParametersSaveProjeTuruMecraEsle;
    stateVars.ProjeTuruMecraEslestir = result?.data.projeTuruMecraEslestir;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProjeTuruMecraEsleComponent_42526_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProjeTuruMecraEsleComponent_42526_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_660947 = this.state.ProjeTuruMecraEslestir;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProjeTuruMecraEsleComponent_575988_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MecraID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_42526_value", "value", "sbMecraList", "id", "id")
        ),
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_356189_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      ProjeTuruId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_356189_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      ),
      MecraGrupId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_235392_value", "value", "sbMecraGrupList", "id", "id")
        ),
        "Guid"
      ),
      MecraId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "projeturumecraesle_42526_value", "value", "sbMecraList", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProjeTuruMecraEsle/ProjeTuruMecraEsleComponent_575988_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MecraProjeTuruEsleInsert = result?.data.mecraProjeTuruEsleInsert;
    stateVars.ProjeTuruMecraEslestir = result?.data.projeTuruMecraEslestir;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProjeTuruMecraEsleComponent_575988_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProjeTuruMecraEsleComponent_575988_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_660947 = this.state.ProjeTuruMecraEslestir;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProjeTuruMecraEsleComponent_871646_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "projeturumecraesle_660947_value", "id"),
        "Guid"
      ),
      ProjeTuruId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      MecraGrupId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      MecraId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "4dd514f6_0709_742a_ba75_3982cd168520_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ProjeTuruMecraEsle/ProjeTuruMecraEsleComponent_871646_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.MecraProjeTuruEsleDelete = result?.data.mecraProjeTuruEsleDelete;
      stateVars.ProjeTuruMecraEslestir = result?.data.projeTuruMecraEslestir;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ProjeTuruMecraEsleComponent_871646_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProjeTuruMecraEsleComponent_871646_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_660947 = this.state.ProjeTuruMecraEslestir;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [464411] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ProjeTuruMecraEslePageInit();
    }
  }
}
